import React from "react";
import { Grid } from "@dls/web";
import { Spinner, Layout } from "./index";

const SpinnerWithLayout = ({ spinnerText = "Redirecting" }) => {
  return (
    <Layout footer={false}>
      <Grid fluid={false}>
        <Spinner spinnerText={spinnerText} />
      </Grid>
    </Layout>
  );
};

export default SpinnerWithLayout;
