export const isDev = () => {
  return process.env.NODE_ENV === "development";
};
const DEV_DOMAIN_URL = "http://localhost:8080";

/* istanbul ignore next */
const DOMAIN = isDev()
  ? DEV_DOMAIN_URL
  : process.env.REACT_APP_DOMAIN_URL || "";

/* istanbul ignore next */
export const VERSION = process.env.REACT_APP_API_VERSION || "/v1";

/* istanbul ignore next */
export const OAuthDomainUrl = isDev()
  ? DEV_DOMAIN_URL
  : process.env.REACT_APP_OAUTH_DOMAIN_URL;

export const ROUTEPREFIX = "/web";

export const APIs = withPrefix(
  {
    INIT_PAYMENT: "/initPayment",
    GET_PAYMENT_INSTRUMENT_INFO: "/getPaymentInstrumentInfo"
  },
  DOMAIN + VERSION
);

export const API = DOMAIN + VERSION;

export const paymentAPI = API + "/initPayment";
export const paymentSummaryAPI = API + "/orderinfo";
export const removeCardAPI = API + "/remove-card";
export const submitOtp = API + "/dash/response";
export const resendOtpAPI = API + "/resend/otp";
export const paymentCancelAPI = API + "/cancel";
export const paymentLookupAPI = API + "/paymentStatus";
export const authPayerAPI = API + "/authPayer";
export const oauthTokenAPI =
  OAuthDomainUrl + "/gateway/digipay-bff/oauth/token";

export const secondsToMilliseconds = 1000;
export const constructRequestUrl = ({ method = "get", path = "" }) => {
  if (method?.toLowerCase() === "get" && path) {
    return `${API}/${path}`;
  }
  if (method?.toLowerCase() === "post") {
    return `${API}/execute`;
  }
};

export const requestUrlMapping = {
  initiatePayment: "initiatePayment",
  instrInfo: "instrInfo",
  authenticatePayer: "authenticatePayer"
};

// Download links for apple & Android
export const appleStoreSingtelDash =
  "https://apps.apple.com/sg/app/singtel-dash/id640036406";
export const playStoreSingtelDash =
  "https://play.google.com/store/apps/details?id=com.SingTel.mWallet&hl=en_SG";

export const PAYMENT_METHOD = {
  CC: "CC",
  ENETS: "ENETS",
  DASH: "DASH",
  SINOPAY: "SINOPAY",
  PAYNOW: "PAYNOW",
  AMEX_CC: "AMEX_CC",
  BNPL: "BNPL",
  EGIRO: "EGIRO",
  AXS_CC: "AXS_CC"
};

export const CARD_TYPES = {
  MASTERCARD: "MASTERCARD",
  VISA: "VISA",
  AMEX: "AMEX"
};

//MPGS Errors
export const MPGS_CODES = {
  SUPPORTED: "SUPPORTED",
  NOT_SUPPORTED: "NOT_SUPPORTED",
  INSUFFICIENT_LENGTH: "INSUFFICIENT_LENGTH",
  EMPTY: "EMPTY",
  INVALID_CHARACTERS: "INVALID_CHARACTERS",
  INVALID_VALUE: "INVALID_VALUE",
  INVALID_LENGTH: "INVALID_LENGTH",
  EXPIRED: "EXPIRED",
  FIELDS_IN_ERROR: "fields_in_error",
  REQUEST_TIMEOUT: "request_timeout",
  SYSTEM_ERROR: "system_error",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
  AWAITING_SERVER_RESPONSE: "AWAITING_SERVER_RESPONSE"
};

//MPGS Error Messages
export const MPGS_ERROR_MSGS = {
  INVALID_CARD: "Enter a valid card number",
  INVALID_EXPIRY_YEAR: "Enter a valid expiry year",
  INVALID_EXPIRY_MONTH: "Enter a valid expiry month",
  INVALID_CVV: "Security code is invalid.",
  INVALID_GIFT_CARD: "Gift card number invalid or missing.",
  INVALID_PIN: "Pin invalid or missing.",
  INVALID_ACCOUNT_HOLDER: "Bank account holder invalid.",
  INVALID_ACCOUNT_NUMBER: "Bank account number invalid.",
  INVALID_ROUTING_NUMBER: "Routing number invalid.",
  INVALID_EMPTY: "Enter your card number",
  INVALID_EXPIRY_MONTH_EMPTY: "Enter an expiry month",
  INVALID_EXPIRY_YEAR_EMPTY: "Enter an expiry year",
  INVALID_EXPIRED: "Enter a valid expiry date",
  INVALID_INCOMPLETE: "Security code is incomplete.",
  REQUEST_TIMEOUT: "Session timeout. Please try again.",
  SYSTEM_ERROR: "Something went wrong. Please try again.",
  GENERIC: "Something went wrong. Please try again."
};

export const ERROR_CODES = {
  paymentTimeout: "paymentTimeout",
  invalidPaymentStatus: "invalidPaymentStatus",
  paymentPlatform: "paymentPlatform",
  retryExceeded: "retryExceeded",
  sessionExpired: "sessionExpired"
};

export const PAYMENT_STATUS_CODES = {
  FAILED: "failed",
  PENDING: "pending",
  SUCCESS: "success"
};

export const GATEWAY_CODES = {
  ABORTED: "ABORTED",
  ACQUIRER_SYSTEM_ERROR: "ACQUIRER_SYSTEM_ERROR",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  AUTHENTICATION_IN_PROGRESS: "AUTHENTICATION_IN_PROGRESS",
  BLOCKED: "BLOCKED",
  CANCELLED: "CANCELLED",
  DECLINED: "DECLINED",
  DECLINED_AVS: "DECLINED_AVS",
  DECLINED_AVS_CSC: "DECLINED_AVS_CSC",
  DECLINED_CSC: "DECLINED_CSC",
  DECLINED_DO_NOT_CONTACT: "DECLINED_DO_NOT_CONTACT",
  DECLINED_INVALID_PIN: "DECLINED_INVALID_PIN",
  DECLINED_PAYMENT_PLAN: "DECLINED_PAYMENT_PLAN",
  DECLINED_PIN_REQUIRED: "DECLINED_PIN_REQUIRED",
  DUPLICATE_BATCH: "DUPLICATE_BATCH",
  EXCEEDED_RETRY_LIMIT: "EXCEEDED_RETRY_LIMIT",
  EXPIRED_CARD: "EXPIRED_CARD",
  INSUFFICIENT_FUNDS: "INSUFFICIENT_FUNDS",
  INVALID_CSC: "INVALID_CSC",
  LOCK_FAILURE: "LOCK_FAILURE",
  NOT_ENROLLED_3D_SECURE: "NOT_ENROLLED_3D_SECURE",
  NOT_SUPPORTED: "NOT_SUPPORTED",
  PARTIALLY_APPROVED: "PARTIALLY_APPROVED",
  PENDING: "PENDING",
  REFERRED: "REFERRED",
  SUBMITTED: "SUBMITTED",
  SYSTEM_ERROR: "SYSTEM_ERROR",
  TIMED_OUT: "TIMED_OUT",
  UNKNOWN: "UNKNOWN",
  UNSPECIFIED_FAILURE: "UNSPECIFIED_FAILURE",
  ENETSPOPUPREFRESH_ERROR: "1099-03205",
  RESEND_OTP_FAILED: "RESEND_OTP_FAILED"
};

export const ERROR_MESSAGES = {
  GENERAL: {
    title: "Payment unsuccessful",
    description:
      "Something went wrong while processing your payment. Please try again.",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false,
    cancelBtn: true
  },
  CANCELLED: {
    title: "Transaction cancelled",
    description:
      "You may start a new transaction or switch to other payment methods.",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false,
    cancelBtn: true
  },
  UNSUCCESSFUL: {
    title: "Payment unsuccessful",
    description:
      "We are unable to process payment with this card. Pay with another card or switch to other payment methods.",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false,
    cancelBtn: true
  },
  INSUFFICIENT_FUNDS: {
    title: "Insufficient balance",
    description:
      "Ensure you have sufficient balance in your account or use another payment method.",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false,
    cancelBtn: true
  },
  CREDIT_LIMIT: {
    title: "Exceeded credit limit",
    description:
      "You may pay with another card or switch to other payment methods.",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false
  },
  SESSION_EXPIRY: {
    title: "Payment session expired",
    description:
      "You may start a new transaction or switch to other payment methods. ",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false,
    cancelBtn: true
  },
  PAYMENT_INTERRUPTED: {
    title: "Transaction incomplete",
    description:
      "Your transaction was interrupted while processing your payment. Try again to complete this transaction.",
    buttonText: "Retry",
    backToPaymentMethod: false,
    backToMerchant: false,
    retryPayment: true
  },
  INCORRECT_CVV: {
    title: "Verification code invalid",
    description: "Try again or switch to other payment methods.",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false,
    cancelBtn: true
  },
  EXPIRED: {
    title: "Card expired",
    description:
      "Use a card with a valid expiry or switch to other payment methods.",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false,
    cancelBtn: true
  },
  DECLINED: {
    title: "Payment declined",
    description:
      "Please contact your bank to continue payment with this card or switch to other payment methods.",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false,
    cancelBtn: true
  },
  RETRY_EXCEEDED: {
    title: "Session terminated",
    description:
      "We have received too many requests. For security reasons, please try again.",
    buttonText: "Back to Home",
    backToPaymentMethod: false,
    backToMerchant: true,
    retryPayment: false
  },
  //There is a duplicate payment with the same correlation id and payment token which is pending or failed
  INVALID_PAYMENT_STATUS: {
    title: "Payment interrupted",
    description:
      "Your payment request could not be completed as the page was interrupted. Try again to complete your payment.",
    buttonText: "Back to Home",
    backToPaymentMethod: false,
    backToMerchant: false,
    retryPayment: false
  },
  //There is a duplicate payment with the same correlation id and payment token which is successful
  INVALID_PAYMENT_STATUS_SUCCESS: {
    title: "Payment successful",
    description:
      "Your payment is successful. Do look out for your order confirmation in your email inbox.",
    buttonText: "Back to Home",
    backToPaymentMethod: false,
    backToMerchant: false,
    retryPayment: false
  },
  //There is a duplicate payment with the same correlation id and payment token which is failed
  INVALID_PAYMENT_STATUS_FAILED: {
    title: "Payment session ended",
    description: "Please try again to complete this transaction.",
    buttonText: "Back to Home",
    backToPaymentMethod: false,
    backToMerchant: false,
    retryPayment: false
  },
  PAYMENT_INIT_FAILED: {
    description: "We couldn't process your transaction. Please try again."
  },
  PAYMENT_CC_BLACKLISTED: {
    description:
      "Oops, sorry we currently accept credit/debit cards issued in Singapore only. Try another card instead?"
  },
  PAYMENT_DELETECARD_FAILED: {
    description: "We couldn't remove your card successfully. Please try again"
  },
  PAYMENT_SESSION_EXPIRED: {
    title: "Payment session expired",
    description:
      "You may start a new transaction or switch to other payment methods.",
    buttonText: "Back to Home",
    backToPaymentMethod: false,
    backToMerchant: true,
    retryPayment: false
  },
  PAYMENT_SESSION_ENDED: {
    title: "Payment session ended",
    description:
      "This session has ended due to a system error. Try again to complete this transaction",
    buttonText: "Retry payment",
    backToPaymentMethod: false,
    backToMerchant: false,
    retryPayment: true
  },
  INTERNAL_ERROR: {
    title: "Something went wrong",
    description: "We are working to fix this. Please try again later.",
    buttonText: "Back to Home",
    backToPaymentMethod: false,
    backToMerchant: true,
    retryPayment: false
  },
  PAYMENT_UNSUCCESSFUL_ENETS: {
    title: "Payment unsuccessful",
    description:
      "We are unable to process payment with this account. Try again with another payment method.",
    buttonText: "Back to Home",
    backToPaymentMethod: false,
    backToMerchant: true,
    retryPayment: false
  },
  PAYMENT_DECLINED_ENETS: {
    title: "Payment declined",
    description:
      "Please contact your bank to continue payment with this account or try again with other payment methods.",
    buttonText: "Back to payment methods",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false,
    cancelBtn: true
  },
  UNKNOWN: {
    title: "Payment unsuccessful",
    description:
      "Something went wrong while processing your payment. Please try again.",
    buttonText: "", // To ensure the button is not shown to the customer for this error.
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false
  },
  ENETSPOPUPREFRESH_ERROR: {
    title: "Payment interrupted",
    description:
      "Your payment request could not be completed as the page was interrupted. Try again to complete your payment.",
    buttonText: "Back to Home",
    backToPaymentMethod: false,
    backToMerchant: false,
    retryPayment: false
  },
  RESEND_OTP_FAILED: {
    title: "Something went wrong",
    description: "We are working to fix this. Please try again later.",
    buttonText: "Back to Home",
    backToPaymentMethod: true,
    backToMerchant: false,
    retryPayment: false
  }
};

//Payment method details
export const PAYMENT_DETAILS = {
  CC: {
    heading: "Credit / Debit Card",
    detail: "Visa, Mastercard",
    icon: "ccIcon"
  },
  ENETS: {
    heading: "Bank Account",
    detail: "Payment via eNETS",
    icon: "eNetsIcon"
  },
  DASH: {
    heading: "Dash",
    icon: "dashIcon"
  },
  PAYNOW: {
    heading: "Scan and Pay",
    detail: "Payment via PayNow QR",
    icon: "payNowIcon"
  },
  SINOPAY: {
    heading: "UnionPay",
    icon: "unionPayIcon"
  },
  AMEX_CC: {
    heading: "Credit Card",
    detail: "Amex",
    icon: "AMEX"
  },
  BNPL: {
    heading: "Instalment plan",
    detail: "Payment via Atome",
    icon: "BNPL"
  },
  EGIRO: {
    heading: "eGiro",
    icon: "eGiroIcon"
  },
  AXS_CC: {
    heading: "Credit / Debit Card",
    detail: "Visa, Mastercard via AXS",
    icon: "ccIcon"
  }
};

export const DEFAULT_HOME_PAGE = "https://www.singtel.com";

export const DEFAULT_ERROR_PAGE = ROUTEPREFIX + "/errorpage";

function withPrefix(apis, prefix) {
  return Object.entries(apis).reduce((apisWithPrefix, [key, value]) => {
    apisWithPrefix[key] = `${prefix}${value}`;
    return apisWithPrefix;
  }, {});
}

export const PAYMENT_LOOKUP_STATUS_COMPLETED = "COMPLETED";
export const PAYMENT_LOOKUP_STATUS_PENDING = "PENDING";
export const PAYMENT_LOOKUP_STATUS_SUCCESS = "SUCCESS";

export const requestHeaderMapping = {
  apisecret: "x-api-secret",
  timestamp: "x-timestamp"
};

export const cancelPayment = "Cancel";
export const threeDSVersion2 = "3DS2";
export const threeDSVersion1 = "3DS1";

export const DASH_ERROR_MESSAGES = {
  "DASH-001": "The mobile number and Dash PIN do not match. Please try again",
  "DASH-002": "Unable to get Balance.",
  "DASH-011": "Insufficient Balance.",
  "DASH-003": "Error From OTP Service.",
  "DASH-004": "Error From OTP Validate Service.",
  "DASH-005": "Error From Sales Request Service.",
  "DASH-006": "Error From Sales Request Execution Service.",
  "DASH-007": "Failed in the Initiate Payment Request.",
  "DASH-008": "Failed in Payment Request Status Service.",
  "DASH-009": "Payment Already Present",
  "DASH-010": "Failed in Password Decryption",
  "DASH-111": "No Data Found",
  "DASH-222": "Telepin Service Error"
};

export const routeMapping = {
  CC: "creditcard",
  ENETS: "enetspay",
  DASH: "dashlogin",
  SINOPAY: "unionpay",
  PAYNOW: "paynow",
  AMEX_CC: "creditcard",
  BNPL: "bnpl",
  EGIRO: "egiro",
  AXS_CC: "axs"
};

export const acsFileName = "acs_v1.html";
export const threedsFileName = "3ds_v1.html";
export const invokeMethodFileName = "im_v1.html";

export const TX_TYPE_MANAGE = "manage";

export const operationTypes = {
  save: "save",
  replace: "replace",
  remove: "remove",
  save_subscription: "save_subscription"
};

export const CURRENCY = {
  SG: "S$"
};

export const PaymentSuccessMessages = {
  payment: {
    title: "Payment Successful",
    subtitle: "We've sent you an email with your payment confirmation details"
  },
  save: {
    title: "New card added",
    subtitle: "You will be automatically redirected in a few moments."
  },
  replace: {
    title: "New card updated",
    subtitle: "You will be automatically redirected in a few moments."
  }
};

export const manageCardErrorRoute = "/managecarderror";

export const manageCardErrorMessages = {
  title: "Request unsuccessful",
  description:
    "Something went wrong while processing your request. Try again later",
  buttonText: "Retry"
};
